<template>
  <!-- right 从右边进入，left从左边进入 -->
  <div class="transition-layout">
    <!-- 页面滑动的动画，若想无动画，去掉transition标签即可 -->
    <transition name="van-slide-right">
      <!-- 设置 -->
      <router-view class="center" name="Security"/>
      <router-view class="center" name="BasicSetting"/>
      <router-view class="right" name="ChangePassword"/>
      <router-view class="right" name="ChangeSecretPhone"/>
      <router-view class="right" name="ChangeEmail"/>
      <!-- 消息 -->
      <router-view class="right" name="MessageDetail"/>
    </transition>
    <transition name="van-slide-left">
      <!-- 设置 -->
      <router-view class="left" name="User"/>
      <router-view class="center" name="SecuritySetting"/>
      <!-- 消息 -->
      <router-view class="left" name="MessageIndex"/>
    </transition>

    <router-view />
  </div>
</template>

<script>

export default {
  name: 'TransitionLayout'
}
</script>

<style>
.left, .center, .right {
  position: absolute;
  width: 100%;
  height: 100%;
}
.right.van-slide-right-leave-active, .right.van-slide-right-enter-active, .center.van-slide-right-leave-active, .center.van-slide-right-enter-active  {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 8;
}
/* .right.van-slide-right-enter-active {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
} */
.center.van-slide-left-leave-active {
  position: absolute;
  width: 100%;
  height: 100%;
  animation-delay: 1s;
  animation-duration: 0s;
  z-index: -7;
}
.center.van-slide-left-enter-active {
  position: absolute;
  width: 100%;
  height: 100%;
  animation-duration: 0s;
}
/* .center.van-slide-right-leave-active {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
} */
/* .center.van-slide-right-enter-active {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
} */
.left.van-slide-left-leave-active {
  position: absolute;
  width: 100%;
  height: 100%;
  animation-delay: 1s;
  animation-duration: 0s;
  z-index: -200;
}
.left.van-slide-left-enter-active {
  position: absolute;
  width: 100%;
  height: 100%;
  animation-duration: 0s;
  z-index: -100;
}
</style>
